import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  paymentPixSummaries: {
    summaries: [],
    totalPaymentAmount: 0,
    totalGrossAmount: 0,
    totalCostAmount: 0,
  },
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_PAYMENT_PIX_SUMMARIES:
      return {
        ...state,
        loading: false,
        paymentPixSummaries: action.payload?.paymentPixSummaries ?? { ...InitialState.paymentPixSummaries },
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    default:
      return { ...state };
  }
};
